<template>
  <a class="bold text-primary-lighten-2" @click.stop.prevent="copySupportEmail">
    <slot>
      {{ supportEmail }}
    </slot>
  </a>
</template>

<script setup>
const supportEmail = ref('support@emissary.ai')
const { copyToClipboard } = useDeviceStore()
const copySupportEmail = async () => {
  await copyToClipboard({
    value: supportEmail.value, text: 'Support email'
  })
}
</script>
